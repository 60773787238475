// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-book-art-product-photos-logos-js": () => import("./../../../src/pages/about/book-art-product-photos-logos.js" /* webpackChunkName: "component---src-pages-about-book-art-product-photos-logos-js" */),
  "component---src-pages-about-book-coauthors-js": () => import("./../../../src/pages/about/book-coauthors.js" /* webpackChunkName: "component---src-pages-about-book-coauthors-js" */),
  "component---src-pages-about-contact-us-js": () => import("./../../../src/pages/about/contact-us.js" /* webpackChunkName: "component---src-pages-about-contact-us-js" */),
  "component---src-pages-about-faq-js": () => import("./../../../src/pages/about/faq.js" /* webpackChunkName: "component---src-pages-about-faq-js" */),
  "component---src-pages-about-licensed-products-js": () => import("./../../../src/pages/about/licensed-products.js" /* webpackChunkName: "component---src-pages-about-licensed-products-js" */),
  "component---src-pages-about-press-releases-js": () => import("./../../../src/pages/about/press-releases.js" /* webpackChunkName: "component---src-pages-about-press-releases-js" */),
  "component---src-pages-ah-js": () => import("./../../../src/pages/ah.js" /* webpackChunkName: "component---src-pages-ah-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-free-stories-email-newsletters-js": () => import("./../../../src/pages/free-stories-email-newsletters.js" /* webpackChunkName: "component---src-pages-free-stories-email-newsletters-js" */),
  "component---src-pages-free-stories-email-newsletters-thank-you-js": () => import("./../../../src/pages/free-stories-email-newsletters/thank-you.js" /* webpackChunkName: "component---src-pages-free-stories-email-newsletters-thank-you-js" */),
  "component---src-pages-hallwayheroes-educators-state-standards-alignments-js": () => import("./../../../src/pages/hallwayheroes/educators-state-standards-alignments.js" /* webpackChunkName: "component---src-pages-hallwayheroes-educators-state-standards-alignments-js" */),
  "component---src-pages-hallwayheroes-educators-teacher-guides-js": () => import("./../../../src/pages/hallwayheroes/educators-teacher-guides.js" /* webpackChunkName: "component---src-pages-hallwayheroes-educators-teacher-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-newsletters-js": () => import("./../../../src/pages/manage-newsletters.js" /* webpackChunkName: "component---src-pages-manage-newsletters-js" */),
  "component---src-pages-manage-newsletters-thank-you-js": () => import("./../../../src/pages/manage-newsletters/thank-you.js" /* webpackChunkName: "component---src-pages-manage-newsletters-thank-you-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-story-submissions-faq-js": () => import("./../../../src/pages/story-submissions/faq.js" /* webpackChunkName: "component---src-pages-story-submissions-faq-js" */),
  "component---src-pages-story-submissions-possible-book-topics-js": () => import("./../../../src/pages/story-submissions/possible-book-topics.js" /* webpackChunkName: "component---src-pages-story-submissions-possible-book-topics-js" */),
  "component---src-pages-story-submissions-story-guidelines-js": () => import("./../../../src/pages/story-submissions/story-guidelines.js" /* webpackChunkName: "component---src-pages-story-submissions-story-guidelines-js" */),
  "component---src-pages-story-submissions-submit-your-story-js": () => import("./../../../src/pages/story-submissions/submit-your-story.js" /* webpackChunkName: "component---src-pages-story-submissions-submit-your-story-js" */),
  "component---src-pages-story-submissions-thank-you-js": () => import("./../../../src/pages/story-submissions/thank-you.js" /* webpackChunkName: "component---src-pages-story-submissions-thank-you-js" */),
  "component---src-templates-about-basic-js": () => import("./../../../src/templates/about-basic.js" /* webpackChunkName: "component---src-templates-about-basic-js" */),
  "component---src-templates-about-legal-js": () => import("./../../../src/templates/about-legal.js" /* webpackChunkName: "component---src-templates-about-legal-js" */),
  "component---src-templates-biography-biography-js": () => import("./../../../src/templates/biography/biography.js" /* webpackChunkName: "component---src-templates-biography-biography-js" */),
  "component---src-templates-books-book-js": () => import("./../../../src/templates/books/book.js" /* webpackChunkName: "component---src-templates-books-book-js" */),
  "component---src-templates-books-js": () => import("./../../../src/templates/books.js" /* webpackChunkName: "component---src-templates-books-js" */),
  "component---src-templates-books-upcoming-js": () => import("./../../../src/templates/booksUpcoming.js" /* webpackChunkName: "component---src-templates-books-upcoming-js" */),
  "component---src-templates-education-hallway-heroes-basic-js": () => import("./../../../src/templates/education/hallway-heroes-basic.js" /* webpackChunkName: "component---src-templates-education-hallway-heroes-basic-js" */),
  "component---src-templates-education-humane-heroes-basic-js": () => import("./../../../src/templates/education/humane-heroes-basic.js" /* webpackChunkName: "component---src-templates-education-humane-heroes-basic-js" */),
  "component---src-templates-education-humane-heroes-volume-js": () => import("./../../../src/templates/education/humane-heroes-volume.js" /* webpackChunkName: "component---src-templates-education-humane-heroes-volume-js" */),
  "component---src-templates-education-school-musicals-basic-js": () => import("./../../../src/templates/education/school-musicals-basic.js" /* webpackChunkName: "component---src-templates-education-school-musicals-basic-js" */),
  "component---src-templates-free-story-free-story-js": () => import("./../../../src/templates/free-story/freeStory.js" /* webpackChunkName: "component---src-templates-free-story-free-story-js" */),
  "component---src-templates-press-release-press-release-js": () => import("./../../../src/templates/press-release/pressRelease.js" /* webpackChunkName: "component---src-templates-press-release-press-release-js" */),
  "component---src-templates-publishing-home-js": () => import("./../../../src/templates/publishing-home.js" /* webpackChunkName: "component---src-templates-publishing-home-js" */),
  "component---src-templates-site-basic-js": () => import("./../../../src/templates/site-basic.js" /* webpackChunkName: "component---src-templates-site-basic-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */)
}

